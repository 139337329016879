<template>
  <Loading v-if="isLoading" />
  <div v-else class="edit-platform">
    <b-card>
      <h2 class="m-0 page-title">
        {{ $t(`platforms.${$route.params.id}`) }}
      </h2>
      <hr />
      <validation-observer ref="replyValidation">
        <b-form @submit.prevent>
          <b-form-checkbox
            switch
            v-model="form_data.value"
            :value="1"
            :unchecked-value="0"
          >
            {{ $t("platforms.active") }}
          </b-form-checkbox>
          <b-row>
            <b-col md="12">
              <b-form-group :label="$t('platforms.message_in_english')">
                <validation-provider
                  #default="{ errors }"
                  name="message in english"
                  rules="required"
                >
                  <b-form-textarea
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.message_en"
                  >
                  </b-form-textarea>
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group :label="$t('platforms.message_in_arabic')">
                <validation-provider
                  #default="{ errors }"
                  name="message in arabic"
                  rules="required"
                >
                  <b-form-textarea
                    :state="errors.length > 0 ? false : null"
                    v-model="form_data.message_ar"
                  >
                  </b-form-textarea>
                  <small class="text-danger">{{ $t(errors[0]) }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-button
            type="submit"
            variant="primary"
            block
            @click="submitForm"
            class="primary-btn mt-3"
            :disabled="isPending"
          >
            <div v-if="isPending" class="loading_" data-loading="light" />
            <span v-else>{{ $t("platforms.save_changes") }}</span>
          </b-button>
        </b-form>
      </validation-observer>
    </b-card>
    <overlay-component :isLoading="overlayLoading" />
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormSelect,
  BFormTextarea,
  BForm,
  BFormGroup,
  BButton,
  BFormCheckbox,
  BFormInput,
} from "bootstrap-vue";
import OverlayComponent from "@/components/shared/OverlayComponent";
import Loading from "@/components/shared/loading/loading.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { email, required } from "@core/utils/validations/validations";

export default {
  name: "EditPlatform",
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BButton,
    Loading,
    BFormSelect,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BFormInput,
    OverlayComponent,
  },
  data() {
    return {
      isEdit: null,
      isPending: null,
      isLoading: true,
      overlayLoading: false,
      current_id: null,
      page_title: null,
      form_data: {
        value: null,
        message_ar: null,
        message_en: null,
      },
    };
  },
  created() {
    if (this.$route.params.id) {
      this.current_id = this.$route.params.id;
      this.getPlatformData();
    }
    this.isLoading = false;
  },
  methods: {
    async getPlatformData() {
      try {
        this.overlayLoading = true;
        const response = (
          await this.$http.get(`admin/settings?key=${this.current_id}`)
        ).data.data;
        this.form_data.value = response.value.value;

        const message_en = (
          await this.$http.get(`admin/settings?key=maintenance_message_en`)
        ).data.data;

        this.form_data.message_en = message_en.value.value;
        const message_ar = (
          await this.$http.get(`admin/settings?key=maintenance_message_ar`)
        ).data.data;
        this.form_data.message_ar = message_ar.value.value;
      } catch (error) {
        this.$helpers.handleError(error);
        this.isError = true;
      } finally {
        this.overlayLoading = false;
      }
    },
    submitForm() {
      this.$refs.replyValidation.validate().then((success) => {
        if (success) {
          this.overlayLoading = true;

          const formData_ar = new FormData();
          const formData_en = new FormData();
          formData_ar.append("value", this.form_data.message_ar);
          formData_en.append("value", this.form_data.message_en);

          this.$http
            .post(
              `admin/settings/maintenance_message_ar?_method=put`,
              formData_ar
            )
            .then((res) => {
              if (res.status === 200 || res.status === 201) {
                this.$helpers.makeToast(
                  "success",
                  res.data.message,
                  res.data.message
                );
                setTimeout(() => {
                  this.$router.push({ name: "platforms-list" });
                }, 1000);
              }
            })
            .catch((err) => {
              this.overlayLoading = false;

              this.$helpers.handleError(err);
              this.isPending = false;
            });
          this.$http
            .post(
              `admin/settings/maintenance_message_en?_method=put`,
              formData_en
            )
            .then((res) => {})
            .catch((err) => {
              this.overlayLoading = false;

              this.$helpers.handleError(err);
              this.isPending = false;
            });
          const data = {
            value: this.form_data.value,
          };

          this.$http
            .post(`admin/settings/${this.$route.params.id}?_method=put`, data)
            .then((res) => {})
            .catch((err) => {
              this.$helpers.handleError(err);
              this.isPending = false;
            });
        } else {
          this.overlayLoading = false;

          this.$helpers.makeToast(
            "warning",
            this.$t("g.something_is_wrong"),
            this.$t("g.please_ensure_that_the_inputs_are_correct")
          );
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
